import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, useDataProvider} from 'react-admin';
import { Grid , Box} from '@mui/material';
import { useNavigate } from 'react-router-dom';


const EditDataFromTask = (props) => {
  const navigate = useNavigate();
  const dataProvider = useDataProvider();


  const onSuccess = (data) => {
    dataProvider.update("datafromtask", { id: data.id, data })
    .then(({ data }) => {
      navigate(-1)
    })
    .catch((error) => {
      console.error('Erro ao obter o recurso personalizado:', error);
    });
  };

  return (
    <Edit {...props}  mutationOptions={{ onSuccess }}>
        <SimpleForm >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box marginTop={-2}>
                            <TextInput label="Requisito" source="requirement_id" disabled={true}/>
                            <TextInput label="Nome" source="name" fullWidth />
                            <TextInput label="Descrição" source="description" fullWidth />
                            <TextInput label="Objetivo" source="goal" fullWidth/>
                            <TextInput label="Armazenamento" source="store" fullWidth/>
                            <BooleanInput label="Pessoal" source="personal_data" />
                            <BooleanInput label="Infantil" source="childish" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box marginTop={-2}>
                            <TextInput label="Coleta" source="collect" fullWidth/>
                            <TextInput label="Departamento" source="department" fullWidth/>
                            <TextInput label="Titular" source="owner" fullWidth/>
                            <TextInput label="Propósito" source="purpose" fullWidth/>
                            <BooleanInput label="Transferível" source="transfer" />
                            <BooleanInput label="Sensível" source="sensitive" />
                            <BooleanInput label="Compartilhado" source="sharing" />
                        </Box>
                    </Grid>
                </Grid>
        </SimpleForm>
    </Edit>
  );
};

export default EditDataFromTask;

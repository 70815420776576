import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';
import { Grid , Box} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const DataFromTaskCreate = (props) => {
    const navigate = useNavigate();
    const onSuccess = () => {
      navigate(-1)
    };

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    return (
      <Create {...props} mutationOptions={{ onSuccess }} >
        <SimpleForm >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box marginTop={-2}>
                            <TextInput label="Requisito" source="requirement_id" defaultValue={id} disabled={true}/>
                            <TextInput label="Nome"  source="name" defaultValue="" fullWidth />
                            <TextInput label="Descrição" source="description" defaultValue="" fullWidth />
                            <TextInput label="Objetivo" source="goal" defaultValue="" fullWidth/>
                            <TextInput label="Armazenamento"  source="store" defaultValue="" fullWidth/>
                            <BooleanInput label="Pessoal"  source="personal_data" defaultValue={false}/>
                            <BooleanInput label="Infantil"  source="childish" defaultValue={false}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box marginTop={-2}>
                            <TextInput label="Coleta"  source="collect" defaultValue="" fullWidth/>
                            <TextInput  label="Departamento" source="department" defaultValue="" fullWidth/>
                            <TextInput  label="Titular" source="owner" defaultValue="" fullWidth/>
                            <TextInput  label="Propósito" source="purpose" defaultValue="" fullWidth/>
                            <BooleanInput  label="Transferível" source="transfer" defaultValue={false}/>
                            <BooleanInput  label="Sensível" source="sensitive" defaultValue={false} />
                            <BooleanInput  label="Compartilhado" source="sharing" defaultValue={false}/>
                        </Box>
                    </Grid>
                </Grid>
        </SimpleForm>    
      </Create>
    );
  };
  
  export default DataFromTaskCreate;
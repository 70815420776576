import { useMediaQuery } from '@mui/material';
import {Datagrid, List, NumberField, SimpleList, TextField } from 'react-admin';
import CustomBulkActionsButton from '../comunication/CustomBulkActionsButton';
import { useDataProvider, FunctionField } from 'react-admin';
import { useEffect, useState } from 'react';
import { Icon, Tooltip } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import AsideComponent from './task/AsideComponent';
import WorkflowConsumerProducer from '../comunication/WorkflowConsumerProducer';

const PostBulkActionButtons = () => (
    <>
        <CustomBulkActionsButton/>
    </>
);

export const TaskList = (props) => {

    const dataProvider = useDataProvider();
    const [eventList, setEventList] = useState([]);
    const [selectedId, setSelectedId] = useState(null);

    const handleButtonClick = (id) => {
        setSelectedId(id);
    };

    /*useEffect(() => {
      const fetchEventList = async () => {
        try {
            const response = await fetch('http://localhost:5000/event');
            const jsonData = await response.json();
            setEventList(jsonData)
            
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchEventList();
    }, [dataProvider]); */
  
    

    const rowStyle = record => {
      if (
        eventList.some(event => event.requirement_id == record.id && event.level == "error") 
        
      ) {
        return {  }; // Estilizar a linha com fundo vermelho
      }
  
      return {}; // Retornar estilo padrão
    };

    const isEventError = record => {
        if (
            eventList.some(event => event.requirement_id == record.id && event.level == "error") 
        ) {
            return true; // Estilizar a linha com fundo vermelho
        }
    
        return false; // Retornar estilo padrão
      };

    const getEventMessage = (record) => {
        const evento = eventList.find(event => event.requirement_id == record.id && event.level == "error") ;
        let toolTipValue = ''
        if(evento){
            evento.message.datas.forEach((item) => {
                toolTipValue += (`${item.name}: ${item.obs}\n` )
            });
        }
        return toolTipValue
    };

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    return (
        <>
                <List {...props} pagination={false} >
                    {isSmall ? (
                        <SimpleList
                            primaryText={(record) => record.id}
                            secondaryText={(record) => record.title}
                            tertiaryText={(record) => record.description}
                        />
                    ) : ( 
                    <Datagrid rowClick="edit" rowStyle={rowStyle} bulkActionButtons={<PostBulkActionButtons />} pagination="false">
                        <NumberField sortable={false} source="id" />
                        <TextField sortable={false} source="title" />
                        <TextField sortable={false} source="description" />
                        <FunctionField
                            sortable={false}
                            source="level"
                            label=""
                            render={record => {
                                const isError = isEventError(record);
                                const message = getEventMessage(record);
                                return (
                                    <Tooltip title={isError ? message : ''}>
                                        <span>
                                        {isError ? (
                                            <Icon color="error">
                                            <ErrorIcon />
                                            </Icon>
                                        ) : (
                                            record.level
                                        )}
                                        </span>
                                    </Tooltip>
                                );
                            }}
                        />



                    </Datagrid>
                    )}
                    {selectedId && ( <AsideComponent selectedId={selectedId}/>)}
                </List>
                <WorkflowConsumerProducer setEventList={setEventList}/>

        </>
    );
};
import React from "react";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";

export const Dashboard = () => (
  <Card>
    <CardHeader title="FHGR - Ferramenta Hipotética de Gerenciamento de Requisitos" />
    <CardContent>
      <Grid container spacing={2}>
        {/* Coluna do Texto */}
        <Grid item xs={6}>
          
          <h3>Sobre a Ferramenta</h3>

          <p>
            Esta ferramenta de gerenciamento de requisitos foi desenvolvida com o propósito de exemplificar a implementação 
            da Visão de Requisitos, uma parte essencial da abordagem.
          </p>
          
          <h3>Sobre a Avaliação</h3>
          <span>
            A avaliação ocorre em duas etapas. Primeiramente, são apresentados alguns casos de uso, juntamente 
            com as análises correspondentes para cada um. Posteriormente, um vídeo é apresentado para explicar 
            a arquitetura e o funcionamento da abordagem. Por fim, um formulário é disponibilizado para avaliação da abordagem.
          </span>
          
          <h3>Formulários de Avaliação</h3>
          <span>
              Para analisar a abordagem do ponto de vista de Entrega de Valor e Flexibilidade/Adaptabilidade da abordagem. 
          </span>
          <ul>
            <li>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSewb9qMkyKoFQnu2_oJCAhtQiXs37h99IkdDpdZOruso3WbrA/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
              Formulário de Avaliação 1
              </a>
            </li>
          </ul>

          <span>
          Para avaliar a ferramenta somente do ponto de vista de Entrega de Valor para a proteção de dados. 
          </span>
          <ul>
          <li>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdMJwGc7YjrDR-LyEv2FH9zWYfzuRZf2xEuIX_eUXFtIOZrJg/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                Formulário de Avaliação 2
              </a>
            </li>
          </ul>

        </Grid>
        {/* Coluna do Vídeo */}
        <Grid item xs={6}>
          <h3>Arquitetura</h3>

          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/qUUruEeDEgo`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

import * as React from "react";
import { Create, SimpleForm, TextInput} from 'react-admin';

export const TaskCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Título" source="title" fullWidth />
            <TextInput label="Descrição"  source="description" multiline fullWidth/>
        </SimpleForm>
    </Create>
);
import React, { useEffect } from 'react';

const WorkflowConsumerProducer = ({setEventList}) => {
  
  const fetchEventList = async () => {
    try {
        const response = await fetch('http://150.162.60.138:5000/event');
        const jsonData = await response.json();
        setEventList(jsonData)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEventList();
    const interval = setInterval(() => {
      fetchEventList();
    }    
    , 10000);
    return () => {
      clearInterval(interval);
    };
  }, [setEventList]);
  
};

export default WorkflowConsumerProducer;

import React from 'react';
import { useNotify, useRefresh, Button, useDataProvider, useRecordContext } from 'react-admin';

const CustomDeleteButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const record = useRecordContext();

  const handleDelete = () => {
    if (!record || !record.id) {
      notify('Invalid record', 'error');
      return;
    }

    dataProvider
      .delete('datafromtask', { id: record.id })
      .then(() => {
        notify('Record deleted successfully');
        refresh();
      })
      .catch((error) => {
        console.error('Error deleting record:', error);
        notify('Error deleting record', 'error');
      });
  };

  return <Button label="Delete" onClick={handleDelete} />;
};

export default CustomDeleteButton;

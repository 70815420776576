import React, { useEffect, useState } from 'react';
import {useRecordContext } from 'react-admin';

const AsideComponent = ({ selectedId }) => {
  const [analysisData, setAnalysisData] = useState(null);
  const record = useRecordContext();

  useEffect(() => {
    const fetchAnalysisData = async () => {
      try {
        const response = await fetch(`http://150.162.60.138:5000/analysis?id=${record.id}`);
        const data = await response.json();
        setAnalysisData(data);
      } catch (error) {
        console.error('Erro ao obter os dados de análise:', error);
      }
    };

    fetchAnalysisData();
  }, [selectedId]);

  return (
    


            <div>
            <h2>Análise dos dados deste requisito</h2>
            {analysisData  && analysisData.analysis && analysisData.analysis.requirement_manager_service ? (
                    <div>
                        <h3>Análise dos dados básicos do requisito</h3>
                        <p>Ação a realizar: {analysisData.analysis.requirement_manager_service.action} | Tipo de alerta: {analysisData.analysis.requirement_manager_service.level}</p>
                        <ul>
                            {analysisData.analysis.requirement_manager_service.message.datas.map((item, index) => (
                              <div>
                              <p>Dado:{ item.name } : { item.obs}</p>
                                  
                                {/* Iterar pelo array "info" */}
                                {item.info.map((infoItem, infoIndex) => (
                                  <ul key={infoIndex}>Informação faltante: {Object.keys(infoItem)[0]}</ul>
                                ))}

                            </div>
                            ))}
                       </ul>
                    </div>
                    ) : (
                        <div>
                            <h3>Análise dos dados enviados</h3>
                            <p> Não há pendências</p>
                        </div>
                )}

                {analysisData  && analysisData.analysis && analysisData.analysis.data_classification_service ? (
                    <div>
                        <h3>Análise de classificação dos dados</h3>
                        <p>Ação a realizar:{analysisData.analysis.data_classification_service.action} | Tipo de Alerta: {analysisData.analysis.data_classification_service.level}</p>
                        <p>Possíveis dados pessoais detectados:</p>
                        <ul>
                            Dados pessoais comuns: 
                            {analysisData.analysis.data_classification_service.message.personal_data_words.map((item, index) => (
                              <p>{item}</p>
                            ))}
                       </ul>
                        <p>Dados pessoais sensíveis</p>
                        <ul>
                            {analysisData.analysis.data_classification_service.message.sensitive_data_words.map((item, index) => (
                              <p>{item}</p>
                            ))}
                       </ul>


                    </div>
                    ) : (
                        <div>
                            <h3>Análise de classificação dos dados</h3>
                            <p> Ainda não foi gerada análise dos tipo de dados deste requisito...</p>
                        </div>
                )}

                {analysisData  && analysisData.analysis && analysisData.analysis.regulation_manager_service ? (

                <div>
                    <h3>Análise de Regulamentos</h3>
                    <p>Ação a realizar: {analysisData.analysis.regulation_manager_service.action} | Tipo de Alerta: {analysisData.analysis.regulation_manager_service.level}</p>
                    <ul>
                      {analysisData.analysis.regulation_manager_service.message.map((item, index) => (
                        <div key={index}>
                        <p>Dado: {item.data}</p>
                        <ul>
                          {item.tip_description.map((tipDescription, index) => (
                            <li key={index}>{tipDescription}</li>
                          ))}
                        </ul>
                      </div>
                      ))}
                    </ul>
                </div>

                ) : (
                    <div>
                        <h3>Análise de Regulamentos</h3>
                        <p> Ainda não foi gerada análise de regulamentos para este requisito... </p>
                    </div>
                )}

            </div>
  
  );
};

export default AsideComponent;

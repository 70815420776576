import * as React from "react";

import { Admin, Resource, Layout } from 'react-admin';
import { DataList } from "./components/DataList";
import { fetchUtils } from 'react-admin';

//Data From Task
import { TaskEdit }  from "./components/TaskEdit";
import { TaskList } from "./components/TaskList";
import { TaskCreate } from "./components/TaskCreate";
import  EditDataFromTask from "./components/task/EditDataFromTask";
import DataFromTaskCreate from "./components/task/DataFromTaskCreate";

import { Dashboard } from "./components/Dashboard";

import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import { CustomMenu } from "./CustomMenu";

const apiUrl1 = 'http://150.162.60.138:5001';
const apiUrl2 = 'http://150.162.60.138:5000';

const httpClient = (url, options = {}) => {

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  var endpoint = apiUrl2;
  
  if(url.startsWith('/task')){
    endpoint = apiUrl1
    url = url.replace('/task', '/requirement')
  }else if(url.startsWith('/datafromtask')){
    endpoint = apiUrl1
    url = url.replace('/datafromtask', '/data')    
  } 

  return fetchUtils.fetchJson(endpoint + url , options);
};

const dataProvider = {

    getList: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        _page: page,
        _start: (page - 1) * perPage,
        _end: page * perPage,
        _limit: perPage,
        _sort: field,
        _order: order,

      };
      const url = `/${resource}?${fetchUtils.queryParameters(query)}`;
  
      return httpClient(url, {
        method: 'GET',
        body: JSON.stringify(params.data),
      }).then((response) => {
        return {
          data: response.json,
          total: parseInt(response.headers.get('X-Total-Count'), 10),
        };
      });
    },
    getOne: (resource, params) =>
    
      httpClient(`/${resource}?id=${params.id}`, {
        method: 'GET',
      }).then((response) => {
        return {
          data: response.json,
        };
      }),
    update: (resource, params) =>
      httpClient(`/${resource}?id=${params.id}`, {

        method: 'PATCH',
        body: JSON.stringify(params.data),
      }).then((response) => {
        return {
          data: response.json,
        };

      }),
    create: (resource, params) =>
      httpClient(`/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then((response) => {
        return {
          data: response.json,
        };
      }),
    delete: (resource, params) =>
      httpClient(`/${resource}?id=${params.id}`, {
        method: 'DELETE',
      }).then((response) => {
        return {
          data: response.json,
        };
      })
  };
  
const App = () => (

    <Admin dataProvider={dataProvider} dashboard={Dashboard} menu={CustomMenu}
      locale="pt"
    >
        <Resource name="datafromtask"  create={DataFromTaskCreate} edit={EditDataFromTask} 
              options={{ label: 'Dado' }} 
        />
        <Resource name="task" list={TaskList} create={TaskCreate} edit={TaskEdit} icon={PlaylistAddCheckRoundedIcon} 
        options={{ label: 'Requisito' }}
        />
        <Resource name="requirement" icon={AssignmentTurnedInIcon} />
        <Resource name="datas" list={DataList} icon={PeopleAltRoundedIcon}/>
    </Admin>

);

export default App;


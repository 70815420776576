import { fetchUtils } from 'react-admin';

const apiUrl = 'http://localhost:5000'; // URL base da sua API

const WorkflowDataProvider = {
  ...fetchUtils,
  create: (resource, params) => {
    const { data, appKey } = params;

    const url = `${apiUrl}/${resource}`;

    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({ 'Content-Type': 'application/json', 'app_key': appKey })
    };

    return fetchUtils.fetchJson(url, options);
  },
};

export default WorkflowDataProvider;

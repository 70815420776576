import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { useMediaQuery } from '@mui/material';
import {TextInput, BooleanField, DatagridConfigurable, List, SimpleList, TextField,


SelectColumnsButton,
FilterButton,
ExportButton,
TopToolbar,
BooleanInput,
NumberInput

} from 'react-admin';

const renderHeaderWithInfo = (field, label, tooltipText) => {
    return (
      <Tooltip title={tooltipText}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {label}
          <InfoIcon
            style={{ fontSize: 16, marginLeft: 5 }}
          />
        </span>
      </Tooltip>
    );
  };
  

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <NumberInput label="Requisito" source="requirement_id"/>,
    <BooleanInput label="Infantil" source="childish" defaultValue={true}/>,
    <BooleanInput label="Sensível" source="sensitive" defaultValue={true}/>,
    <BooleanInput label="Compartilhado" source="sharing" defaultValue={true}/>,
];

const PostListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <ExportButton />
        <FilterButton disableSaveQuery/>
    </TopToolbar>
);

export const DataList = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    return (

        <List actions={<PostListActions/>} filters={postFilters} bulkActionButtons={false} >
            {isSmall ? (
                <SimpleList
                    primaryText={(record) => record.name}
                    secondaryText={(record) => record.description}
                    tertiaryText={(record) => record.goal}
                />
            ) : (
            <DatagridConfigurable bulkActionButtons={false}>
                <TextField sortable={false}  source="requirement_id" label="Requisito" />
                <TextField sortable={false}  source="name" label="Dado"/>
                <TextField sortable={false}  source="description" label={renderHeaderWithInfo('description', 'Descrição','Descrição do tratamento (desde a coleta até a eliminação)') }/> 
                <TextField sortable={false} source="department" label="Departamento"/>
                <TextField sortable={false}  source="purpose" label="Propósito"/>
                <TextField sortable={false}  source="goal" label="Objetivo"/>
                <TextField sortable={false}  source="owner" label="Titular"/>
                <BooleanField sortable={false}  source="sensitive" label={renderHeaderWithInfo('sensitive', 'Sensível','Dados pessoais sensíveis (informar todos os tipos de dados pessoais sensíveis tratados, de forma completa);') }/>
                <BooleanField sortable={false}  source="childish" label={renderHeaderWithInfo('childish', 'Infantil','Dados de crianças e adolescentes ou de outra categoria de vulneráveis, como idosos, se houver;') }/>
                <TextField sortable={false}  source="collect" label={renderHeaderWithInfo('collect', 'Coleta','Indicar a forma de coleta do dado') } />
                <TextField sortable={false}  source="transfer" label="Transferência"/>
                <TextField sortable={false}  source="store" label={renderHeaderWithInfo('store', 'Armazenamento','Política de armazenamento (descrever os prazos de retenção e métodos de descarte);') }/>
                <BooleanField sortable={false}  source="personal_data" label="Pessoal"/>
                <BooleanField sortable={false}  source="sharing" label={renderHeaderWithInfo('sharing', 'Compartilhado',' Informar quais são os compartilhamentos internos e externos (inclusive transferência internacional, se houver);') }/>
            </DatagridConfigurable>
            )}
        </List>
    );
};

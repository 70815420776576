// in src/MyMenu.js
import { Menu } from 'react-admin';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';


export const CustomMenu = () => (
    <Menu>
        <Menu.DashboardItem primaryText="Sobre"/>
        <Menu.Item to="/task" primaryText="Requisitos" leftIcon={<PlaylistAddCheckRoundedIcon />}/>
        <Menu.Item to="/datas" primaryText="Mapeamento dos Dados" leftIcon={<PeopleAltRoundedIcon />}/>
    </Menu>
);
const WorkflowRequirementTemplate = (data) => {
    
    return { 
        id_requirement: data.id,
        title: data.title,
        description: data.description,
        datas: data.datas.map((item) => ({
            name: item.name,
            info: { 
              personal_data: item.personal_data ,
              description: item.description ,
              childish: item.childish,
              collect: item.collect,
              department: item.department,
              goal: item.goal,
              owner: item.owner,
              purpose: item.purpose,
              sensitive: item.sensitive,
              sharing: item.sharing,
              store: item.store,
              transfer: item.transfer          
            },
        })),
    };
  };

export default WorkflowRequirementTemplate;
import { Edit, EditButton, SimpleForm, TextInput, Datagrid, BooleanField, TextField, SimpleShowLayout, ArrayField } from 'react-admin';
import CustomDeleteButton from './CustomDeleteButton';
import React, { useState } from 'react';
import { Show, TabbedShowLayout } from 'react-admin'
import AsideComponent from './task/AsideComponent';
import { Link } from 'react-router-dom';

export const TaskEdit = (props) => {

    const [fieldValue, setFieldValue] = useState('');

    const handleInputChange = (event) => {
      setFieldValue(event.target.value);
    };

return (
<Show>
<TabbedShowLayout>
    <TabbedShowLayout.Tab label="Task">
    <Edit>
        <SimpleForm>
            <TextInput id="requi" source="id" disabled={true} onAnimationEnd={handleInputChange}/>
            <TextInput source="title" label="Título" fullWidth />
            <TextInput source="description" label="Descrição" multiline fullWidth/>
            <SimpleShowLayout >
                <ArrayField  source="datas" label="">

                        <Link
                            to={`/datafromtask/create?id=${fieldValue}`}
                            style={{
                            display: 'inline-block',
                            padding: '5px 10px',
                            backgroundColor: '#2196f3',
                            color: 'white',
                            borderRadius: '5px',
                            textDecoration: 'none',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                           + Adicionar Dado
                        </Link>

                        <Datagrid {...props} bulkActionButtons={false}  >
                            <TextField source="id"/>
                            <TextField source="name" /> 
                            <TextField source="collect" />
                            <TextField source="department" />
                            <BooleanField source="childish" />
                            <BooleanField source="sensitive" />
                            <BooleanField source="sharing" />
                            <CustomDeleteButton />
                            <EditButton  resource='datafromtask'/>
                        </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </SimpleForm>
    </Edit>
    </TabbedShowLayout.Tab>
    <TabbedShowLayout.Tab label="Analise">
        <AsideComponent/>
    </TabbedShowLayout.Tab>

    </TabbedShowLayout>
    </Show>
);
};
import React from 'react';
import {useDataProvider, Button, useListContext, useNotify } from 'react-admin';
import WorkflowDataProvider from '../components/task/WorkflowDataProvider';
import WorkflowRequirementTemplate from '../template/WorkflowRequirementTemplate';

const CustomBulkActionsButton = () => {
  const dataProvider = useDataProvider(WorkflowDataProvider);
  const notify = useNotify();
  const { selectedIds } = useListContext();

  const handleBulkDelete = () => {

    selectedIds.forEach((id) => {
      
      dataProvider.getOne('task', { id  }).then((response) => {
            dataProvider.create('send', {data: WorkflowRequirementTemplate(response.data)} ).then((response) => {
              notify('Análise iniciada!');
            }).catch((error) => {
              notify('Análise iniciada!');
            });
        })
        .catch((error) => {
          console.error(`Error creating item with ID: ${id}`, error);
        });
    });
  };

  return (
    <Button label="Analisar" onClick={handleBulkDelete}
    />
  );
};

export default CustomBulkActionsButton;
